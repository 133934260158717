import {BaseGraph} from "../base_graph";

class DrivingAvailable extends BaseGraph {
    constructor() {
        super('driving_available');
    }

    getChartJsOptions(options, data) {
        options.indexAxis = 'y';
        options.plugins = {
            legend: {
                display: false
            },
            annotation: {}
        };
        return options;
    }
}

$(() => {
    if(app && app.DASHBOARD_CORE) {
        new DrivingAvailable();
    } else {
        console.warn('DashboardCore not initalized.');
    }
});